.background {
/*  background-image: url('../assets/Naas.png'); */
  background-image: url('../assets/Landing-page-globesmall.png');
  /* Additional background styles */
  background-size: 200%; /* Adjust as needed */
  width: 100vw; /* Adjust as needed */
  height: 700px; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}
.backgroundh1 {
  /* Additional background styles */
	background-color:#00fff111;
  background-size: 100%; /* Adjust as needed */
  width: 75vw; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}


.container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
