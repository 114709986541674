/* ButtonStyles.css */

.borderless-btn {
  cursor: pointer;
  border: none; /* Remove border */
  background-color: transparent; /* Set background color to transparent */
/*  color: #3498db; */ /* Text color */
  color: #333333;  /* Text color */
  font-size: 1.0em;
}

