body {
    color: white;
    font-family: Switzer, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
}
div {
  /*background-color: #191970; */
  background-color: #000503;
}

a {
  color: #fff;
  margin-left: 2.0rem;
}

p {
  color: white;
  margin-left: 2.00rem;
  font-family:sans-serif;
  font-size: 1.25rem;
  font-weight: normal;
}


h1 {
  margin-left: 4.0rem;
}
h2 {
  margin-left: 2.0rem;
}

h3 {
  margin-left: 2.0rem;
}
.photo {
  height: 400px;
  width: 700px;
  align-items: center;
  margin-left: 5rem;
}
.image-with-text {
  position: relative;
  display: inline-block;
  position: center;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.container {
  display: grid;
/*  grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
  grid-template-columns: 300px 1fr; /* Three columns with equal width */
  gap: 1px; /* Spacing between the boxes */
}

.box {
  padding: 20px;
  border: 1px solid #ccc;
}
