.scrolling-text-container {
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
}

.scrolling-text {
  white-space: nowrap;
  animation: scrollText 30s linear infinite; /* Adjust duration and timing function as needed */
  overflow-x: hidden;
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

