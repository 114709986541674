/* CompanyDropdown.css */

.company-dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #f1f1f1;
  color: black;
  padding: 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
}

.company-dropdown:hover .dropdown-content {
  display: block;
}

