/* styles.css */
.background-container {
  background-color: #f0f0f0; /* Use a hexadecimal color value */
  /* Additional styles for the container */
}

.background-div {
  background-color: rgb(255, 0, 0); /* Use an RGB color value */
  /* Additional styles for the div */
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}

.background-span {
  background-color: rgba(0, 255, 0, 0.5); /* Use an RGBA color value with alpha (opacity) */
  /* Additional styles for the span */
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}

